import React from 'react'
import { Box } from 'rebass'

export default ({ active }) => {
  return (
    <Box width={30} mr={10}>
      <svg
        width='20'
        height='27'
        viewBox='0 0 20 27'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          opacity={active ? '1' : '0.2'}
          d='M13.0977 7.36421V1.63649C13.0977 0.732688 13.8307 0 14.7349 0C15.6391 0 16.3721 0.732688 16.3721 1.63649V7.36421H13.0977ZM18.8279 8.18245H0.818604C0.366479 8.18245 0 8.54877 0 9.0007V10.6372C0 11.0891 0.366479 11.4554 0.818604 11.4554H1.63721V13.0919C1.63721 17.0505 4.44957 20.3524 8.18604 21.1105V26.1839H11.4605V21.1105C15.1969 20.3524 18.0093 17.0505 18.0093 13.0919V11.4554H18.8279C19.28 11.4554 19.6465 11.0891 19.6465 10.6372V9.0007C19.6465 8.54877 19.28 8.18245 18.8279 8.18245ZM6.54883 7.36421V1.63649C6.54883 0.732688 5.81582 0 4.91163 0C4.00743 0 3.27442 0.732688 3.27442 1.63649V7.36421H6.54883Z'
          fill={active ? '#00FFC2' : 'white'}
        />
      </svg>
    </Box>
  )
}
