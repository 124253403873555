import React from 'react'
import { Box } from 'rebass'

export default ({ active }) => {
  return (
    <Box width={30} mr={10}>
      <svg
        width='17'
        height='27'
        viewBox='0 0 17 27'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          opacity={active ? '1' : '0.2'}
          d='M15.1428 8.18245H9.23853L11.0097 1.54362C11.2174 0.764855 10.6293 0 9.82322 0H2.86508C2.24965 0 1.72927 0.455405 1.64797 1.06515L0.0109697 13.3388C-0.0872117 14.0747 0.486221 14.7284 1.22808 14.7284H7.30115L4.94465 24.6735C4.76067 25.4499 5.35461 26.1839 6.13909 26.1839C6.5663 26.1839 6.97693 25.9602 7.20215 25.5713L16.2054 10.0248C16.6784 9.20823 16.0884 8.18245 15.1428 8.18245Z'
          fill={active ? '#00FFC2' : 'white'}
        />
      </svg>
    </Box>
  )
}
