import React from 'react'
import { Box } from 'rebass'

export default ({ active }) => {
  return (
    <Box width={30} mr={10}>
      <svg
        width='20'
        height='27'
        viewBox='0 0 20 27'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          opacity={active ? '1' : '0.2'}
          d='M11.0512 1.22013C11.0512 0.00288921 9.48286 -0.455482 8.79237 0.553364C2.45581 9.81135 11.4605 10.2281 11.4605 14.7284C11.4605 16.5505 9.97091 18.0248 8.14255 18.0011C6.34316 17.9778 4.91163 16.4785 4.91163 14.6797V10.3066C4.91163 9.19674 3.5573 8.65865 2.79185 9.46262C1.42238 10.901 0 13.3646 0 16.3649C0 21.7791 4.4067 26.1839 9.82325 26.1839C15.2398 26.1839 19.6465 21.7791 19.6465 16.3649C19.6465 7.65624 11.0512 6.49469 11.0512 1.22013Z'
          fill={active ? '#00FFC2' : 'white'}
        />
      </svg>
    </Box>
  )
}
